<template>
  <div id="mian">
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="商户名称"
          ></el-input>
        </div>
        <!-- <el-select v-model="query.mchId" placeholder="全部" style="width:200px;height:36px;" >
            <el-option
            style="width:200px"
              v-for="item in MerchantsDropList"
              :key="item.mchId"
              :label="item.mchName"
              :value="item.mchId">
            </el-option>
          </el-select> -->
       <div class="item itemTime">
        <i>统计日期</i>
        <el-date-picker
          v-model="query.startDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
        <span style="padding:0px 10px;">-</span>
        <el-date-picker
          v-model="query.endDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
       </div>
       <div class="itembtn" style="width:12%;">
        <el-button class="searchBt" @click="onSearch">查询</el-button>
       </div>
      </div>


      <div class="header">
        <div
          :class="{ right: true, active: timeActive == 0 ? true : false }"
          @click="checkTimeActive(0)"
        >
          近30天
        </div>
        <div
          :class="{ right: true, active: timeActive == 1 ? true : false }"
          @click="checkTimeActive(1)"
        >
          近7天
        </div>
        <!-- <div :class="{right:true, active:timeActive == 2?true:false}" @click="checkTimeActive(2)">今天</div> -->
        <div
          :class="{ right: true, active: timeActive == 3 ? true : false }"
          @click="checkTimeActive(3)"
        >
          昨天
        </div>
      </div>

    </div>
    <div class="desc_title">
      总收益:{{ (totalStatistics.totalProfit / 100) | formatMoney }}元
    </div>
    <div class="exharts">
      <ve-line
        ref="chartline"
        :data="chartData"
        :extend="chartExtend"
        :legend-visible="false"
      ></ve-line>
    </div>
    <div class="echarts clear">
      <div style="float: left">
        <div class="labels">收益结构</div>
        <div class="charts">
          <ve-pie
            class="pie"
            :data="pieData"
            :settings="pieSettings"
            :extend="pieExtend"
            :tooltip-visible="false"
            :legend-visible="false"
          ></ve-pie>
        </div>
      </div>
      <div style="float: right; width: 404px">
        <div class="labels" style="margin-bottom: 60px">收益分析</div>
        <div class="desc">
          <span></span>
          支付收益：{{ (totalStatistics.tradeProfit / 100) | formatMoney }}元
          <span style="background-color: #f2637b; margin-left: 64px"></span>
          代付收益：{{ (totalStatistics.agnpayProfit / 100) | formatMoney }}元
        </div>
        <div class="desc2">
          <span
            >交易净额：{{
              (totalStatistics.tradeNetAmount / 100) | formatMoney
            }}元</span
          >
          <span style=""
            >代付净额：{{
              (totalStatistics.agnpayNetAmount / 100) | formatMoney
            }}元</span
          >
        </div>
        <div class="desc2">
          <span>交易笔数：{{ totalStatistics.tradeNum }}笔</span>
          <span style="">代付笔数：{{ totalStatistics.agnpayNum }}笔</span>
        </div>
        <div class="desc2">
          <span
            >总手续费：{{
              (totalStatistics.tradeServiceCost / 100) | formatMoney
            }}元</span
          >
          <span style=""
            >总手续费：{{
              (totalStatistics.agnpayServiceCost / 100) | formatMoney
            }}元</span
          >
        </div>
        <div class="desc2">
          <span
            >平台成本：{{
              (totalStatistics.tradeCost / 100) | formatMoney
            }}元</span
          >
          <span style=""
            >代付成本：{{
              (totalStatistics.agnpayCost / 100) | formatMoney
            }}元</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { merchantsDrop, payproductsDrop } from "@/api/common.js";
import { profitsurveySituation } from "@/api/data/clearing.js";
import { formatDate } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex"; 
export default {
  data() {
    return {
      active: 0,
      timeActive: 3,
      chartData: {
        columns: ["date", "user"],
        rows: [],
      },
      chartExtend: {
        tooltip: {
          backgroundColor: "#48B8B6",
          formatter(res) {
            let data = res[0].value;
            function money(value) {
              if (!value) return "0.00";

              var intPart = Number(value) - (Number(value) % 1); //获取整数部分（这里是windy93的方法）
              var intPartFormat = intPart
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断

              var floatPart = ".00"; //预定义小数部分
              var value2Array = value.toString().split(".");

              //=2表示数据有小数位
              if (value2Array.length == 2) {
                floatPart = value2Array[1].toString(); //拿到小数部分

                if (floatPart.length == 1) {
                  //补0,实际上用不着
                  return intPartFormat + "." + floatPart + "0";
                } else {
                  return intPartFormat + "." + floatPart;
                }
              } else {
                return intPartFormat + floatPart;
              }
            }
            return `${data[0]}  ${money(data[1])}元`;
          },
        },
        series: {
          smooth: false,
        },
      },
      pieSettings: {
        radius: 100,
        label: {
          position: "inner",
        },
      },
      pieData: {
        columns: ["key", "value"],
        rows: [],
      },
      pieExtend: {
        color: ["#48B8B6", "#F2637B"],
      },
      query: {
        startDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        endDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        mchId: "",
        keyword: "",
      },
      MerchantsDropList: [],
      periodStatistics: [],
      totalStatistics: {
        totalProfit: 0,
        tradeProfit: 0,
        agnpayProfit: 0,
        tradeNetAmount: 0,
        agnpayNetAmount: 0,
        tradeNum: 0,
        agnpayNum: 0,
        tradeServiceCost: 0,
        agnpayServiceCost: 0,
        tradeCost: 0,
        agnpayCost: 0,
      },
    };
  },
  // computed: {
  //   ...mapState({
  //     platform_overview_sygk: "platform_overview_sygk",
  //   }),
  //   isCollapse(){return this.$store.state.isActiveHead;}
  // },
  // watch:{
  //   isCollapse(newValue, oldValue){
  //     this.$nextTick(() => {
  //       this.$refs.chartline.echarts.resize()
  //     })
  //   }
  // },
  created() {
    if (this.platform_overview_sygk) {
      this.query = this.platform_overview_sygk.query;
    }
    this.getLists();
    this.getMerchantsDrop();
    this.$enter(this.$route.path, this.onSearch);

    var localValue;
    this.bus.$on('isActiveHead', res => {
      this.$nextTick(() => {
        this.$refs.chartline.echarts.resize()
      })
    })

  },
  activated() {
    this.getLists();
    this.getMerchantsDrop();
  },
  methods: {
    ...mapMutations({
      platform_setoverview_sygk: "platform_setoverview_sygk",
    }),
    getLists() {
      this.query.keyword = this.query.keyword.trim();
      if (!this.query.startDate || !this.query.endDate) {
        this.$message.error("统计日期时间段必选");
        return;
      }
      this.judgeTime(this.query.startDate,this.query.endDate)
      profitsurveySituation(this.query)
        .then((res) => {
          this.periodStatistics = res.resultData.periodStatistics;
          this.totalStatistics = res.resultData.totalStatistics;
          this.chartData.rows = [];
          this.pieData.rows = [];
          this.periodStatistics.forEach((item) => {
            this.chartData.rows.push({
              date: item.totalDate,
              user: item.totalProfit / 100,
            });
          });
          this.pieData.rows.push({
            key: "支付收益",
            value: this.totalStatistics.tradeProfit / 100,
          });
          this.pieData.rows.push({
            key: "代付收益",
            value: this.totalStatistics.agnpayProfit / 100,
          });
        })
        .catch();
      
      this.$nextTick(() => {
        this.$refs.chartline.echarts.resize()
      })

    },
    // 获取商户下拉
    getMerchantsDrop() {
      merchantsDrop()
        .then((res) => {
          this.MerchantsDropList = res.resultData;
        })
        .catch();
    },
    checkTimeActive(timeActive) {
      this.timeActive = timeActive;
      switch (timeActive) {
        case 0:
          this.query.startDate = formatDate(
            +new Date() - 29 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.query.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case 1:
          this.query.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.query.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case 2:
          this.query.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.query.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case 3:
          this.query.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.query.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
      }
      this.getLists();
    },
    onSearch() {
      this.getLists();
    },
    //时间范围 是否添加active判断
    judgeTime(startDate,endDate){
      if(startDate == formatDate(+new Date() - 29 * 24 * 60 * 60 * 1000,"yyyy-MM-dd") && endDate == formatDate(+new Date(), "yyyy-MM-dd")){
        this.timeActive = 0;
      }else if(startDate == formatDate(+new Date() - 6 * 24 * 60 * 60 * 1000,"yyyy-MM-dd") && endDate == formatDate(+new Date(), "yyyy-MM-dd")){
        this.timeActive = 1;
      }else if(startDate == formatDate(+new Date() - 24 * 60 * 60 * 1000,"yyyy-MM-dd") && endDate == formatDate(+new Date() - 24 * 60 * 60 * 1000,"yyyy-MM-dd")){
        this.timeActive = 3;
      }else{
        this.timeActive = null;
      }
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.platform_setoverview_sygk(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item{
  margin-left: 6%;
}
.con_from .lineP .item:first-child {
    margin-left: 0%;
}
.header span {
  display: inline-block;
  height: 100%;
  width: 100px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.header {
  float: right;
  display: inline-block;
  line-height: 40px;
  height: 40px;
  position: relative;
  top: 20px;
  border-bottom:none;
  /* border:0 */
}

.header div {
  cursor: pointer;
}

.header .right {
  display: inline-block;
  float: right;
  width: 74px;
  height: 40px;
  color: #333333;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
}

.header .right.active {
  color: #48b8b6;
}

.desc_title {
  font-size: 14px;
  color: #000000;
  padding-left: 15px;
}

.echarts {
  padding: 48px;
}

.echarts .labels {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(153, 153, 153, 1);
}

.charts {
  width: 300px;
  height: 300px;
  margin-top: -50px;
  margin-left: 97px;
}

.desc {
  font-size: 16px;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.desc span {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 16px;
  background-color: #48b8b6;
}

.desc2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 14px;
  color: #000000;
  margin-left: 24px;
  margin-bottom: 8px;
}
</style>
